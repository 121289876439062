import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import settings from "../settings/api";
import fetchData from "../Helpers/apiCalls";
// import CopyRight from "../components/CopyRight";

export default function Home() {
    const [portalInfo, setPortalInfo] = useState({});

    useEffect(() => {
        getPortalBranding();

        return () => {};
    }, []);

    const getPortalBranding = async () => {
        const result = await fetchData(settings.api().portalInfo, "GET");
        if (result.success) {
            // update tab title
            document.title = result.data.portalTitle;

            // get favicon element
            let link = document.querySelector("link[rel~='icon']");
            // if favicon did exist, create one
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
            }
            // update favicon
            link.href = result.data.theme.favIconUri;
            setPortalInfo(result.data);
        }
        return result;
    };

    return (
        <div>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{
                    minHeight: "100vh",
                    backgroundImage: `url(${portalInfo?.theme?.loginImageUri})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Box
                    sx={{
                        position: "absolute", // Position it absolutely
                        top: 0, // Align to top
                        left: 0, // Align to left
                        right: 0, // Align to right
                        bottom: 0, // Align to bottom
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker semi-transparent background
                        display: "flex", // Use flexbox for alignment
                        flexDirection: "column", // Stack items vertically
                        alignItems: "center", // Center items horizontally
                        justifyContent: "center", // Center items vertically
                        padding: "20px", // Padding around the content
                        // borderRadius: "8px", // Optional: rounded corners
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Optional shadow for the box
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h3"
                        sx={{
                            color: "#fff", // White text color
                            fontWeight: "bold",
                            mb: "16px", // Margin below the text
                            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)", // Text shadow for visibility
                            textAlign: "center", // Center align the text
                        }}
                    >
                        {portalInfo?.landingPageTitle?.split("/")[0]}
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                        sx={{
                            color: "#fff", // White text color
                            fontWeight: "bold",
                            mb: "16px", // Margin below the text
                            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)", // Text shadow for visibility
                            textAlign: "center", // Center align the text
                        }}
                    >
                        {portalInfo?.landingPageTitle?.split("/")[1]}
                    </Typography>
                    <Button
                        component={Link}
                        to="/login"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ m: 1 }} // Margin around the button
                        startIcon={<LockOpenIcon />}
                    >
                        {portalInfo?.landingPageButtonText}
                    </Button>
                    {/* <CopyRight /> */}
                </Box>
            </Grid>
        </div>
    );
}
