import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ListItem, ListItemText, Collapse, List, ListItemIcon, ListItemButton, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { drawerToggle } from "../../settings/redux/componentsActions";
import Icon from "@mui/material/Icon";
import { useTheme } from "@emotion/react";
import GrandchildMenuItem from "./GrandchildMenuItem";

const useStyles = styled((theme) => ({
    nested: {
        paddingLeft: theme.spacing(6),
    },
}));

function MenuItem(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMounted = useRef(false);
    const [selectedPath, setSelectedPath] = useState("");
    const [toggle, setToggle] = useState(false);
    const [grandchildToggle, setGrandchildToggle] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        // isMounted.current && setSelectedPath(location.pathname);
        if (isMounted.current) {
            setSelectedPath(location.pathname);
    
            // Automatically open the parent if the path matches a nested child
            if (props.children?.some((child) => location.pathname.startsWith(child.path))) {
                setToggle(true);
            }
    
            if (props.children?.some((child) => child.children?.some((grandchild) => location.pathname.startsWith(grandchild.path)))) {
                setGrandchildToggle(true);
            }
        }

        return () => {
            isMounted.current = false;
        };
    }, [location]);

    const handleToggle = () => {
        if (!props.openDrawer) {
            props.openAction();
            setToggle(true);
        } else {
            setToggle(!toggle);
        }
    };

    const handleGrandchildToggle = () => {
        if (!props.openDrawer) {
            props.openAction();
            setGrandchildToggle(true);
        } else {
            setGrandchildToggle(!grandchildToggle);
        }
    };

    const renderMenuItems = (props) => {
        if (props.itemType === 1 || props.menuItem === false) {
            return (
                <ListItem sx={{ py: 0.5, mt: 1 }}>
                    {!props.drawerOpen && <ListItemIcon></ListItemIcon>}
                    <ListItemText
                        primary={
                            <Typography style={{ fontFamily: "Inter", fontWeight: "700", fontSize: "12px" }}>
                                {props.label}
                            </Typography>
                        }
                        secondary={props.subtitle}
                    />
                </ListItem>
            );
        } else {
            if (props.itemType === 3 || props.children?.length > 0) {
                return (
                    <div>
                        <ListItem sx={{ py: 0.5, px: 0 }}>
                            <ListItemButton onClick={handleToggle} sx={{ py: 0 }}>
                                <ListItemIcon sx={{ minWidth: props.drawerOpen ? 40 : undefined }}>
                                    <Icon style={{ color: "#949ca7" }}>{props.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "14px" }}
                                        >
                                            {props.label}
                                        </Typography>
                                    }
                                />
                                {toggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={props.drawerOpen && toggle} timeout="auto" unmountOnExit>
                            <List sx={{ py: 0 }}>
                                {props.children.map((i, index) => {
                                    if (i.path.includes("//")) {
                                        const oId = JSON.parse(
                                            localStorage.getItem("userOrganizationInfo")
                                        )?.organizationId;

                                        i.path.replace("//", `/${oId}/`);
                                    }

                                    if (i?.children?.length > 0) {
                                        return (
                                            <GrandchildMenuItem
                                                key={index}
                                                item={i}
                                                selectedPath={selectedPath}
                                                openDrawer={props.drawerOpen}
                                                openAction={() => props.openAction()}
                                            />
                                        );
                                    } else {
                                        return (
                                            <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                                                <ListItemButton
                                                    key={i.label}
                                                    onClick={() => {
                                                        if (i.path.includes("//")) {
                                                            const oId = JSON.parse(
                                                                localStorage.getItem("userOrganizationInfo")
                                                            )?.organizationId;
                                                            i.path.replace("//", `/${oId}/`);
                                                        }
                                                        navigate(i.path);
                                                    }}
                                                    selected={selectedPath === `${i.path}`}
                                                    sx={{
                                                        py: 0,
                                                        paddingLeft: "56px",
                                                        "&.Mui-selected": {
                                                            backgroundColor: theme.palette.primary.dark,
                                                        },
                                                        ":hover": {
                                                            backgroundColor: theme.palette.primary.dark,
                                                        },
                                                    }}
                                                    className={classes.nested}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Inter",
                                                                    fontWeight: "600",
                                                                    fontSize: "14px",
                                                                }}
                                                            >
                                                                {i.label}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    }
                                })}
                            </List>
                        </Collapse>
                    </div>
                );
            } else if (props.itemType === 2 || props.menuItem) {
                return (
                    <ListItem sx={{ py: 0.5, px: 0 }}>
                        <ListItemButton
                            component={Link}
                            to={props.path}
                            selected={selectedPath === `${props.path}`}
                            sx={{
                                py: 0,
                                "&.Mui-selected": {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                                ":hover": {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: props.drawerOpen ? 40 : undefined }}>
                                <Icon style={{ color: "#949ca7" }}>{`${props.icon}`}</Icon>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "14px" }}>
                                        {props.label}
                                    </Typography>
                                }
                                secondary={props.subtitle ? props.subtitle : undefined}
                            />
                        </ListItemButton>
                    </ListItem>
                );
            }
        }
    };

    return <>{renderMenuItems(props)}</>;
}

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.componentsReducer.drawerOpen,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        openAction: () => {
            dispatch(drawerToggle());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
// export default MenuItem;
