import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fetchData from "../Helpers/apiCalls";
import settings from "../settings/api";

i18n.use(initReactI18next).init({
    fallbackLng: "en-CA", // Default language
    lng: "en-CA", // Initial language
    interpolation: {
        escapeValue: false, // React already escapes by default
    },
    react: {
        useSuspense: true, // Enable suspense for lazy loading translations
    },
});

export default i18n;

// Function to fetch translations from the API
export async function fetchTranslations(language) {
    try {
        const result = await fetchData(settings.api().translations, "GET");
        // console.log(result);

        if (result.success) {
            return result.data; // API should return translations in key-value pairs
        }
        // console.log(result);

        // return result; // API should return translations in key-value pairs
    } catch (error) {
        console.error("Error fetching translations:", error);
        return {};
    }
}

// Function to load translations dynamically and switch language
export async function loadTranslations(language) {
    const translations = await fetchTranslations(language);

    i18n.addResourceBundle(language, "translation", translations, true, true);
    i18n.changeLanguage(language);
}
