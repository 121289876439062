import React, { Suspense, lazy } from "react";
import Login from "../pages/Login";
import Home from "../pages/Home";
import PortalLayout from "../layout/Portal/Portal";
import Error404 from "../pages/404";
import Versions from "../pages/Versions";
import TmpPage from "../pages/TmpPage";
import ProtectedRouteWrapper from "../Helpers/PrivateRoute";

// Standard
import LoadingSpinner from "../components/Spinner";
import { EditSiteForm } from "../components/Users/EditSiteForm";

// Sites Admin
// import SitesAdminDashboard from "../pages/SitesAdmin/Dashboard";
// import TireQuoteTires from "../pages/SitesAdmin/TireQuote/Data/Tires";
// import TireQuoteWheels from "../pages/SitesAdmin/TireQuote/Data/Wheels";
// import TireWheelPackages from "../pages/SitesAdmin/TireQuote/Data/Packages";
// import TireQuoteAccessories from "../pages/SitesAdmin/TireQuote/Data/Accessories";
// import PricingTemplates from "../pages/SitesAdmin/TireQuote/Pricing/Templates";

// Portal Admin
// import Groups from "../pages/PortalAdmin/Groups/Groups";
// import OrgAdminDashboard from "../pages/PortalAdmin/Dashboard";
// import OrgAdminSites from "../pages/PortalAdmin/Sites/Sites";
// import GroupTypes from "../pages/PortalAdmin/GroupTypes/GroupTypes";
// import OrgAdminTrainingSettings from "../pages/PortalAdmin/Training/Settings";
// import OrgAdminAssessmentSettings from "../pages/PortalAdmin/Assessment/Settings";
// import OrgAdminTACSettings from "../pages/PortalAdmin/TAC/Settings";
// import OrgAdminTrainingCourses from "../pages/PortalAdmin/Training/Courses/Courses";
// import OrgAdminTrainingCourseCategories from "../pages/PortalAdmin/Training/Courses/CourseCategories";
// import OrgAdminTrainingLessons from "../pages/PortalAdmin/Training/Courses/Lessons";
// import PortalUsersList from "../pages/PortalAdmin/Users/List";
// import PortalTireQuoteSettings from "../pages/PortalAdmin/TireQuote/Settings";

// System Admin
// import MultiOrgAdminDashboard from "../pages/SystemAdmin/Dashboard";
// import MultiOrgList from "../pages/SystemAdmin/Organizations/Organizations";
// import TPOSchedules from "../pages/SystemAdmin/TPO/TPOSchedules";
// import TPOBookings from "../pages/SystemAdmin/TPO/TPOBookings";
// import AddEditTPOSchedule from "../pages/SystemAdmin/TPO/AddEditTPOSchedule";
// import TPOSchedule from "../pages/SystemAdmin/TPO/Schedule/TPOSchdule";
// import SystemAdminOrganization from "../pages/SystemAdmin/Organizations/Organization";
// import SitesTireQuoteSettings from "../pages/SitesAdmin/TireQuote/Setting";
// import PortalTPOSettings from "../pages/PortalAdmin/TPO/Settings";
// import SystemTPOSettings from "../pages/SystemAdmin/TireQuote/Settings";
// import SystemTireQuoteSettings from "../pages/SystemAdmin/TireQuote/Settings";
// import BookingWizard from "../pages/SitesAdmin/TPO/BookingWizard/BookingWizard";
// import RequestPasswordReset from "../pages/UserAuthPages/RequestPasswordReset";
// import ResetPassword from "../pages/UserAuthPages/ResetPassword";

//#region Public
const AccountActivation = lazy(() => import("../pages/UserAuthPages/AccountActivation"));
const UserAuthNotification = lazy(() => import("../pages/UserAuthPages/Notification"));
const UserAuthEmailStatus = lazy(() => import("../pages/UserAuthPages/EmailStatus"));
const UserAuthEmailVerification = lazy(() => import("../pages/UserAuthPages/EmailVerification"));
const PrivacyPolicyPage = lazy(() => import("../pages/PrivacyPolicy"));
const TermsAndConditionsPage = lazy(() => import("../pages/TermsAndConditions"));
const RequestPasswordReset = lazy(() => import("../pages/UserAuthPages/RequestPasswordReset"));
const ResetPassword = lazy(() => import("../pages/UserAuthPages/ResetPassword"));
//#endregion

//#region Standard
const DashboardPage = lazy(() => import("../pages/StandardPages/Dashboard/Dashboard"));
const MySite = lazy(() => import("../pages/StandardPages/MySite/MySite"));
const AddUser = lazy(() => import("../components/Users/AddUser"));
const UserDetails = lazy(() => import("../components/Users/UserDetails"));
const NewAssessments = lazy(() => import("../pages/StandardPages/NewAssessments"));
const Notifications = lazy(() => import("../pages/StandardPages/Notifications"));
const Orders = lazy(() => import("../pages/StandardPages/TireQuotePage/Orders"));
const Vehicles = lazy(() => import("../pages/StandardPages/TireQuotePage/Vehicles"));
const Tires = lazy(() => import("../pages/StandardPages/TireQuotePage/Tires"));
const Wheels = lazy(() => import("../pages/StandardPages/TireQuotePage/Wheels"));
const Packages = lazy(() => import("../pages/StandardPages/TireQuotePage/Packages"));
const Accessories = lazy(() => import("../pages/StandardPages/TireQuotePage/Accessories"));
const Reports = lazy(() => import("../pages/StandardPages/TireQuotePage/Reports"));
const Order = lazy(() => import("../pages/StandardPages/TireQuotePage/Order"));
const UserProfile = lazy(() => import("../pages/StandardPages/Site/UserProfile"));
const UserSecurity = lazy(() => import("../pages/StandardPages/Site/UserSecurity"));
const Profile = lazy(() => import("../pages/StandardPages/Profile"));
const TireQuotePage = lazy(() => import("../pages/StandardPages/TireQuotePage"));
const ProductDetailPage = lazy(() => import("../pages/StandardPages/TireDetails"));
const Cases = lazy(() => import("../pages/StandardPages/Cases"));
const Lessons = lazy(() => import("../pages/StandardPages/Lessons"));
const Assessments = lazy(() => import("../pages/StandardPages/Assessments"));
//#endregion

//#region Site Admin
const Pricing = lazy(() => import("../pages/SitesAdmin/TireQuote/Pricing"));
const Featured = lazy(() => import("../pages/SitesAdmin/TireQuote/Featured"));
const Promotions = lazy(() => import("../pages/SitesAdmin/TireQuote/Promotions"));
const Stock = lazy(() => import("../pages/SitesAdmin/TireQuote/Stock"));
const TPO = lazy(() => import("../pages/SitesAdmin/TPO/TPO"));
const Settings = lazy(() => import("../pages/SitesAdmin/TPO/Settings"));
const SitesAdminDashboard = lazy(() => import("../pages/SitesAdmin/Dashboard"));
const TireQuoteTires = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Tires"));
const TireQuoteWheels = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Wheels"));
const TireWheelPackages = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Packages"));
const TireQuoteAccessories = lazy(() => import("../pages/SitesAdmin/TireQuote/Data/Accessories"));
const PricingTemplates = lazy(() => import("../pages/SitesAdmin/TireQuote/Pricing/Templates"));
//#endregion

//#region Portal Admin
const Groups = lazy(() => import("../pages/PortalAdmin/Groups/Groups"));
const OrgAdminDashboard = lazy(() => import("../pages/PortalAdmin/Dashboard"));
const OrgAdminSites = lazy(() => import("../pages/PortalAdmin/Sites/Sites"));
const GroupTypes = lazy(() => import("../pages/PortalAdmin/GroupTypes/GroupTypes"));
const OrgAdminTrainingSettings = lazy(() => import("../pages/PortalAdmin/Training/Settings"));
const OrgAdminAssessmentSettings = lazy(() => import("../pages/PortalAdmin/Assessment/Settings"));
const OrgAdminTACSettings = lazy(() => import("../pages/PortalAdmin/TAC/Settings"));
const OrgAdminTrainingCourses = lazy(() => import("../pages/PortalAdmin/Training/Courses/Courses"));
const OrgAdminTrainingCourseCategories = lazy(() => import("../pages/PortalAdmin/Training/Courses/CourseCategories"));
const OrgAdminTrainingLessons = lazy(() => import("../pages/PortalAdmin/Training/Courses/Lessons"));
const PortalUsersList = lazy(() => import("../pages/PortalAdmin/Users/List"));
const PortalTireQuoteSettings = lazy(() => import("../pages/PortalAdmin/TireQuote/Settings"));
const PortalTPOSettings = lazy(() => import("../pages/PortalAdmin/TPO/Settings"));
//#endregion

//#region System Admin
const MultiOrgAdminDashboard = lazy(() => import("../pages/SystemAdmin/Dashboard"));
const MultiOrgList = lazy(() => import("../pages/SystemAdmin/Organizations/Organizations"));
const TPOSchedules = lazy(() => import("../pages/SystemAdmin/TPO/TPOSchedules"));
const TPOBookings = lazy(() => import("../pages/SystemAdmin/TPO/TPOBookings"));
const AddEditTPOSchedule = lazy(() => import("../pages/SystemAdmin/TPO/AddEditTPOSchedule"));
const TPOSchedule = lazy(() => import("../pages/SystemAdmin/TPO/Schedule/TPOSchdule"));
const SystemAdminOrganization = lazy(() => import("../pages/SystemAdmin/Organizations/Organization"));
const SitesTireQuoteSettings = lazy(() => import("../pages/SitesAdmin/TireQuote/Setting"));
const SystemTPOSettings = lazy(() => import("../pages/SystemAdmin/TireQuote/Settings"));
const SystemTireQuoteSettings = lazy(() => import("../pages/SystemAdmin/TireQuote/Settings"));
const BookingWizard = lazy(() => import("../pages/SitesAdmin/TPO/BookingWizard/BookingWizard"));
//#endregion

const protectedRoute = (Component) => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRouteWrapper Component={Component} />
        </Suspense>
    );
};

export const routes = [
    //#region Public
    {
        index: true,
        element: <Home />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "activation",
        element: <AccountActivation />,
    },
    {
        path: "notification",
        element: <UserAuthNotification />,
    },
    {
        path: "email-status",
        element: <UserAuthEmailStatus />,
    },
    {
        path: "verify-email",
        element: <UserAuthEmailVerification />,
    },
    {
        path: "terms-and-conditions",
        element: <TermsAndConditionsPage />,
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
    },
    {
        path: "forgot-password",
        element: <RequestPasswordReset />,
    },
    {
        path: "reset-password",
        element: <ResetPassword />,
    },
    //#endregion
    //#region Standard
    {
        path: "portal",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },

            {
                path: "tire-quote",
                children: [
                    {
                        index: true,
                        element: <TireQuotePage />,
                    },
                    {
                        path: "orders",
                        element: <Orders />,
                    },
                    {
                        path: "orders/:id",
                        element: <Order />,
                    },
                    {
                        path: "vehicles",
                        element: <Vehicles />,
                    },
                    {
                        path: "tires",
                        element: <Tires />,
                    },
                    {
                        path: "tires/:id",
                        element: <ProductDetailPage />,
                    },
                    {
                        path: "wheels",
                        element: <Wheels />,
                    },
                    {
                        path: "packages",
                        element: <Packages />,
                    },
                    {
                        path: "accessories",
                        element: <Accessories />,
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                ],
            },
            {
                path: "lessons",
                element: <Lessons />,
            },
            {
                path: "assessments",
                children: [
                    {
                        index: true,
                        element: <Assessments />,
                    },
                    {
                        path: "new",
                        element: <NewAssessments />,
                    },
                ],
            },
            {
                path: "cases",
                element: <Cases />,
            },
            {
                path: "site",
                children: [
                    {
                        index: true,
                        element: <MySite />,
                    },
                    {
                        path: "edit",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "users/:id",
                        children: [
                            {
                                index: true,
                                element: <UserProfile />,
                            },
                            {
                                path: "security",
                                element: <UserSecurity />,
                            },
                        ],
                    },
                    {
                        path: "add-user",
                        // element: <NewEditUser mode="create" />
                        element: <AddUser />,
                    },
                ],
            },
            {
                path: "account",
                element: <Profile />,
            },
            {
                path: "notifications",
                element: <Notifications />,
            },
            {
                path: "user",
                element: <UserDetails />,
            },
            {
                path: "versions",
                element: <Versions />,
            },
            {
                path: "*",
                element: <Error404 />,
            },
        ],
    },
    //#endregion 
    //#region Site Admin
    {
        path: "admin/sites/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <SitesAdminDashboard />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "data/tires",
                        element: <TireQuoteTires />,
                    },
                    {
                        path: "data/wheels",
                        element: <TireQuoteWheels />,
                    },
                    {
                        path: "data/packages",
                        element: <TireWheelPackages />,
                    },
                    {
                        path: "data/accessories",
                        element: <TireQuoteAccessories />,
                    },
                    {
                        path: "pricing",
                        element: <Pricing />,
                    },
                    {
                        path: "pricing/templates",
                        element: <PricingTemplates />,
                    },
                    {
                        path: "featured",
                        element: <Featured />,
                    },
                    {
                        path: "promotions",
                        element: <Promotions />,
                    },
                    {
                        path: "stock",
                        element: <Stock />,
                    },
                    // {
                    //     path: "tpo",
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <SitesTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    {
                        index: true,
                        element: <TPO />,
                    },
                    {
                        path: "booking",
                        element: <BookingWizard />,
                    },
                    {
                        path: "booking/:bookingId",
                        element: <BookingWizard />,
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
        ],
    },
    //#endregion
    //#region Portal Admin
    {
        path: "admin/organizations/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <OrgAdminDashboard />,
            },
            {
                path: "sites",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <OrgAdminSites />,
                    },
                    {
                        path: ":id",
                        element: <TmpPage />,
                    },
                    {
                        path: "groups",
                        element: <Groups />,
                    },
                    {
                        path: "group-types",
                        element: <GroupTypes />,
                    },
                ],
            },
            {
                path: "users",
                element: <PortalUsersList />,
            },
            {
                path: "users/:id",
                element: <TmpPage />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "settings",
                        element: <PortalTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    // {
                    //     index: true,
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <PortalTPOSettings />,
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        path: "courses",
                        element: <OrgAdminTrainingCourses />,
                    },
                    {
                        path: "lessons",
                        element: <OrgAdminTrainingLessons />,
                    },
                    {
                        path: "course-categories",
                        element: <OrgAdminTrainingCourseCategories />,
                    },
                    {
                        path: "settings",
                        element: <OrgAdminTrainingSettings />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminAssessmentSettings />,
                    },
                ],
            },
            {
                path: "tac",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminTACSettings />,
                    },
                ],
            },
        ],
    },
    //#endregion
    //#region System Admin
    {
        path: "admin/global",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <MultiOrgAdminDashboard />,
            },
            {
                path: "organizations",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <MultiOrgList />,
                    },
                    {
                        path: ":id",
                        element: <SystemAdminOrganization />,
                    },
                ],
            },
            {
                path: "tire-quote",
                children: [
                    // {
                    //     path: "tpo",
                    //     children: [
                    //         {
                    //             path: "schedules",
                    //             element: <TPOSchedules />,
                    //         },
                    //         {
                    //             path: "schedules/:id",
                    //             element: <TPOSchedule />,
                    //         },
                    //         {
                    //             path: "schedules/add",
                    //             element: <AddEditTPOSchedule mode={"create"} />,
                    //         },
                    //         {
                    //             path: "bookings",
                    //             element: <TPOBookings />,
                    //         },
                    //     ],
                    // },
                    {
                        path: "settings",
                        element: <SystemTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    {
                        path: "schedules",
                        element: <TPOSchedules />,
                    },
                    {
                        path: "schedules/add",
                        element: <AddEditTPOSchedule mode={"create"} />,
                    },
                    {
                        path: "schedules/:id",
                        element: <TPOSchedule />,
                    },
                    {
                        path: "bookings",
                        element: <TPOBookings />,
                    },
                    {
                        path: "settings",
                        element: <SystemTPOSettings />,
                    },
                ],
            },
        ],
    },
    //#endregion
    {
        path: "*",
        element: <Error404 />,
    },
];
