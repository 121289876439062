const getSubdomain = () => {
    const hostname = window.location.hostname;

    const subdomain = hostname.split(".")[0];
    return subdomain;
    // return "vw";
    // return "nissan";
};

const getDomain = () => {
    const hostname = window.location.hostname; // Get the full domain name
    const parts = hostname.split("."); // Split the domain into parts

    // If the hostname has at least two parts (like subdomain.domain.com),
    // return the second-level and top-level domain
    if (parts.length >= 2) {
        return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }

    return hostname;
    // return "tirelance.com";
    // return "motorsaas.com";
};

export { getSubdomain, getDomain };
