import React from "react";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function CopyRight({ copyrightTitle, createdByTitle, copyrightName, copyrightUrl }) {
    const { t } = useTranslation();
    const [copyright, setCopyright] = useState({ copyrightName, copyrightUrl });

    useEffect(() => {
        const tmp = JSON.parse(localStorage.getItem("userOrganizationInfo"));

        if (!!tmp) {
            setCopyright({
                copyrightName: tmp?.copyrightName,
                copyrightUrl: tmp?.copyrightUrl,
            });
        }

        return () => {};
    }, []);

    return (
        <div style={{ marginTop: "20px" }}>
            <Typography variant="body2" color="textSecondary" align="center">
                {!!copyrightTitle ? copyrightTitle : t("base.base_copyright", "Copyright ©")} {new Date().getFullYear()}{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit" }}
                    href={copyright?.copyrightUrl}
                >
                    {copyright?.copyrightName}
                </a>
                <br />
                {!!createdByTitle ? createdByTitle :t("base.base_created_by", "Created By")}{" "}
                <a target="_blank" rel="noopener noreferrer" style={{ color: "inherit" }} href="https://saphera.com">
                    Saphera Software
                </a>
            </Typography>
        </div>
    );
}
